<template>
    <div class="goodorbad">
        <div class="goods_info">
            <div class="infos" v-for="(item, index) in orderDetails" :key="item.pId">
                <div class="infos_page">
                    <img :src="item.goodsPic" /><span>{{ item.name }}</span>
                </div>
                <div class="infos_text">
                    <div>评论内容&nbsp;&nbsp;:</div>
                    <div>
                        <textarea v-model="item.goodsCommentContent"></textarea>
                    </div>
                </div>
                <div>
                    <span>产品描述&nbsp;&nbsp;:&nbsp;&nbsp;</span><el-rate v-model="item.goodsCommentGrade1"
                        @change="(e) => changeFen(e, index)" :colors="colors"></el-rate>
                    <!-- <span>物流服务&nbsp;&nbsp;:&nbsp;&nbsp;</span><el-rate v-model="value2" :colors="colors"></el-rate>
                    <span>客服服务&nbsp;&nbsp;:&nbsp;&nbsp;</span><el-rate v-model="value3" :colors="colors"></el-rate> -->
                </div>
            </div>
        </div>
        <div class="good_sub" @click="submin">提交评价</div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'GoodOrBad',
    data() {
        return {
            goodInfo: null,
            orderDetails: [],
            text: '',
            colors: ['#99A9BF', '#F7BA2A', '#FF9900']
        }
    },
    created() {
        this.goodInfo = JSON.parse(this.$route.query.goodInfo)
        this.getInfo()
    },
    methods: {
        async getInfo() {
            const loading = this.$loading({
                lock: true,
                text: "请稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await this.$api.getInfo({
                id: this.goodInfo.id,
                orderNumber: this.goodInfo.orderNumber,
            })

            loading.close()
            if (res && res.code == 200) {
                for (let i = 0; i < res.data.goodsList.length; i++) {
                    this.orderDetails.push({})
                    this.orderDetails[i].name = res.data.goodsList[i].name
                    this.orderDetails[i].goodsPic = res.data.goodsList[i].goodsPic

                    this.orderDetails[i].goodsId = res.data.goodsList[i].goodsId
                    this.orderDetails[i].beGoodsId = res.data.goodsList[i].beGoodsId
                    this.orderDetails[i].goodsCommentContent = ''
                    this.orderDetails[i].goodsCommentGrade1 = 0
                    this.orderDetails[i].orderId = res.data.goodsList[i].orderId
                }
            } else {
                this.$message.error('网络失败请刷新重试')
            }
        },

        changeFen(goodsCommentGrade1, index) {
            let a = JSON.parse(JSON.stringify(this.orderDetails))
            a[index].goodsCommentGrade1 = goodsCommentGrade1
            this.orderDetails = a
        },
        async submin() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            let test = this.orderDetails.map(item => {
                if (item.goodsCommentGrade1 == 0) {
                    this.$message.error("星级评价不能为空");
                    return
                }
                delete item.name;
                delete item.goodsPic;
                if (item.goodsCommentContent && item.goodsCommentContent != '') {
                    item.goodsCommentContent = item.goodsCommentContent.replace(/\s/g, "")
                }
                return item
            })
            let res = await this.$api.PingJia(test)
            loading.close();
            if (res && res.code == 200) {
                this.$message.success("评论成功!")
                this.$router.replace({ path: "MySelf", });
            } else {
                this.$message.error("评论失败!")
            }
        }
    }
}
</script>

<style lang="scss">
.goodorbad {
    text-align: center;

    .goods_info {
        width: max(275px, 50%);
        padding-top: 3%;
        margin: auto;
        text-align: center;
        font-size: max(14px, 1vw);

        .infos {
            display: flex;
            margin: 2% auto;
            text-align: start;
            flex-direction: column;

            .infos_page {
                height: 10vw;

                >img {
                    height: 100%;
                    margin-right: 5%;
                }
            }

            .infos_text {
                >div:nth-child(1) {
                    text-align: start;
                    width: 20vw;
                    padding-bottom: 1%;
                    white-space: nowrap;
                }

                textarea {
                    resize: none;
                    width: 100%;
                    height: max(50px, 6vw);
                    padding: 0.5%;
                    box-sizing: border-box;
                }
            }

            >div:nth-child(3) {
                display: flex;
            }

            >div {
                margin-top: 1%;
            }
        }
    }

    .good_sub {
        display: inline-block;
        margin: 5% auto 0%;
        padding: 1% 4%;
        background-color: #439C4C;
        border-radius: 30px;
        cursor: pointer;
        color: white;
        font-weight: bold;
    }
}
</style>